const resources = {
    dashboard: {
        name: "userStatistics",
        app: { name: "nestrom-nwe", find: "userstatistics" },
        alias: "Dashboard",
        requiredFilters: ["nestromUserID", "startDate", "endDate"],
    },
    ivivaProcessjc: {
        name: "process-to-nestrom/iviva-job-cards/jc",
        app: { name: "process-to-nestrom", find: "processjc" },
        alias: "Iviva Process Job Card",
        requiredFilters: ["from", "to"],
    },
    ivivaProcessppm_workorder: {
        name: "process-to-nestrom/ppm-work-orders",
        app: { name: "process-to-nestrom", find: "processppm_workorder" },
        alias: "Iviva Process PPM Workorder",
        requiredFilters: ["from", "to"],
    },
    ivivaProcesssnapcard: {
        name: "process-to-nestrom/iviva-job-cards/snap",
        app: { name: "process-to-nestrom", find: "processsnapcard" },
        alias: "Iviva Process Snap Card",
        requiredFilters: ["from", "to"],
    },
    nestromUser: {
        name: "nestrom-api/users",
        app: { name: "nestrom-api", find: "users" },
        alias: "Nestrom Users",
        requiredFilters: [],
    },
    quality: {
        name: "quality-totals",
        calculationMethod: ['default'],
        app: { name: "quality-result", find: "gettotals" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "Quality Audit List",
        defaultSort: {
            field: "zoneName",
            order: "ASC",
        },
    },
    oers: {
        calculationMethod: ['mall'],
        name: "oer-item-calculations",
        app: { name: "oer-item-calculation", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "OERs List",
        defaultSort: {
            field: "oer_item.Ref",
            order: "ASC",
        },
    },
    sppvs: {
        name: "sppv-item-calculations",
        calculationMethod: ['mall'],
        app: { name: "sppv-item-calculation", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "SPPVs List",
        defaultSort: {
            field: "sppv_item.index",
            order: "ASC",
        },
    },
    fmReviews: {
        name: "fm-review-item-calculations",
        calculationMethod: ['mall'],
        app: { name: "fm-review-item-calculation", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "FM Reviews List",
        defaultSort: {
            field: "fm_review_item.index",
            order: "ASC",
        },
    },
    opms: {
        name: "opm-item-calculations",
        calculationMethod: ['mall'],
        app: { name: "opm-item-calculation", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "OPMs List",
        defaultSort: {
            field: "opm_item.Ref",
            order: "ASC",
        },
    },
    qaas: {
        name: "qaa-item-calculations",
        calculationMethod: ['mall'],
        app: { name: "qaa-item-calculation", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "QAAs List",
        defaultSort: {
            field: "qaa_item.Ref",
            order: "ASC",
        },
    },
    regulatoryCompliance: {
        name: "regulatory-compliance-item-calculations",
        calculationMethod: ['mall'],
        app: { name: "regulatory-compliance-item-calculation", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "Regulatory Compliance",
        defaultSort: {
            field: "regulatory_compliance_item.Ref",
            order: "ASC",
        },
    },
    slas: {
        name: "sla-item-calculations",
        calculationMethod: ['default', 'mall'],
        app: { name: "sla-item-calculation", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "SLAs List",
        defaultSort: {
            field: "sla_item.Ref",
            order: "ASC",
        },
    },
    kpis: {
        name: "kpi-item-calculations",
        calculationMethod: ['default'],
        app: { name: "kpi-item-calculation", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "KPIs List",
        defaultSort: {
            field: "kpi_item.index",
            order: "ASC",
        },
    },
    ncrs: {
        name: "ncr-totals",
        calculationMethod: ['default'],
        app: { name: "ncr-total-percentage", find: "gettotals" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "NCRs List",
        defaultSort: {
            field: "criticality",
            order: "ASC",
        },
    },
    totals: {
        name: "prs-total-percentages",
        app: { name: "prs-total-percentage", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "Summary",
    },
    downloadSummaryPDF: {
        name: "prs-total-percentages/export/downloadSummaryPDF_default",
        app: { name: "prs-total-percentage", findOne: "downloadsummarypdf_default" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "Summary-pdf",
    },
    cost_centers: {
        name: "cost-centers",
        app: { name: "cost-center", find: "find", findOne: "findOne" },
        requiredFilters: [],
        alias: "Cost Centers",
        allowPagingation: true,
    },
    vendors: {
        name: "vendors",
        app: { name: "vendor", find: "find", findOne: "findOne" },
        requiredFilters: [],
        alias: "Vendors",
        allowPagingation: true,
    },
    "quality-audit-configuration-percentages": {
        name: "quality-audit-configuration-percentages",
        app: { name: "quality-audit-configuration-percentage" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "QA Percentage Configuration",
    },
    sla_item: {
        name: "sla-items",
        app: { name: "sla-item", find: "find" },
        requiredFilters: [],
        alias: "SLA Item",
    },
    kpi_item: {
        name: "kpi-items",
        app: { name: "kpi-item", find: "find" },
        requiredFilters: [],
        alias: "KPI Item",
    },
    oer_item: {
        name: "oer-items",
        app: { name: "oer-item", find: "find" },
        requiredFilters: [],
        alias: "OER Item",
    },
    sppv_item: {
        name: "sppv-items",
        app: { name: "sppv-item", find: "find" },
        requiredFilters: [],
        alias: "SPPV Item",
    },
    fm_review_item: {
        name: "fm-review-items",
        app: { name: "fm-review-item", find: "find" },
        requiredFilters: [],
        alias: "FM Review Item",
    },
    opm_item: {
        name: "opm-items",
        app: { name: "opm-item", find: "find" },
        requiredFilters: [],
        alias: "OPM Item",
    },
    qaa_item: {
        name: "qaa-items",
        app: { name: "qaa-item", find: "find" },
        requiredFilters: [],
        alias: "QAA Item",
    },
    regulatory_compliance_item: {
        name: "regulatory-compliance-items",
        app: { name: "regulatory-compliance-item", find: "find" },
        requiredFilters: [],
        alias: "Regulatory Compliance Item",
    },
    "sla-penalty-configs": {
        name: "sla-penalty-configs",
        app: { name: "sla-penalty-configs", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "SLA Penalty Configs",
    },
    "sla-penalty-configs-settings": {
        name: "sla-penalty-configs-settings",
        app: { name: "sla-penalty-configs-settings", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year"],
        alias: "SLA Penalty Configs Settings",
    },
    "kpi-penalty-configs": {
        name: "kpi-penalty-configs",
        app: { name: "kpi-penalty-configs", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "KPI Penalty Configs",
        defaultSort: {
            field: "upperBound",
            order: "DESC",
        },
    },
    deduction_summaries: {
        name: "deduction-summaries",
        app: { name: "deduction-summary", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "Deduction Summaries",
        defaultSort: {
            field: "index",
            order: "ASC",
        },
    },
    deduction_summaries_update: {
        name: "deduction-per-types",
        app: { name: "deduction-per-type", update: "update" },
        requiredFilters: [],
        alias: "Deduction Summaries",
    },
    "invoice-summaries": {
        name: "invoice-summaries",
        app: { name: "invoice-summary", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "Invoice Summaries",
    },
    "invoice-summary-settings": {
        name: "invoice-summary-settings",
        app: { name: "invoice-summary-settings", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year"],
        alias: "Invoice Summary Settings",
    },
    comments: {
        name: "comments",
        app: { name: "comments", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "Comments",
    },
    task_completion_rate: {
        name: "taskCompletionRate",
        app: { name: "nestrom-nwe", find: "taskcompletionrate" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
    },
    send_to_iviva_update: {
        name: "prs-total-percentages/send-to-iviva",
        app: {
            name: "prs-total-percentage",
            update: "submitprs_result",
            find: "find",
        },
    },
    "ncr-categories": {
        name: "ncr-categories",
        app: { name: "ncr-category", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "NCR Penalty Configs",
        defaultSort: {
            field: "criticality",
            order: "ASC",
        },
    },
    "prs-configuration-percentage-settings": {
        name: "prs-configuration-percentage-settings",
        app: { name: "prs-configuration-percentage-settings", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year"],
        alias: "PRS Configuration Percentage",
    },
    "quality-audit-zone-configs": {
        name: "quality-audit-zone-configs",
        app: { name: "quality-audit-zone-config", find: "find" },
        requiredFilters: ["cost_center", "vendor", "year", "month"],
        alias: "QA Zone Config",
        defaultSort: {
            field: "criticality",
            order: "ASC",
        },
    },
    "user-type-percentages": {
        name: "user-type-percentages",
        app: { name: "user-type-percentages", find: "find" },
        alias: "User Type Percentages",
        requiredFilters: ["cost_center", "vendor", "year", "month"],
    },
    users: {
        name: "users",
        app: { name: "users", find: "find" },
        alias: "Users",
        requiredFilters: [],
        allowPagingation: true,
    },
    roles: {
        name: "users-permissions/roles",
        app: { name: "users", find: "find" },
        alias: "Roles",
        requiredFilters: [],
        defaultSort: {
            field: "name",
            order: "ASC",
        },
    },
    auditLogs: {
        name: "audit-logs",
        app: { name: "audit-log", find: "find" },
        alias: "Audit Logs",
        requiredFilters: [],
        allowPagingation: true,
        defaultSort: {
            field: "createdAt",
            order: "DESC",
        },
    },
    "zone-location-mappings": {
        name: "zone-location-mappings",
        app: { name: "zone-location-mapping", find: "find" },
        alias: "Zone Location Mappings",
        requiredFilters: [],
        defaultSort: {
            field: "zoneName",
            order: "ASC",
        },
    },
    "prs-approvals": {
        name: "prs-approvals",
        app: { name: "prs-approval", find: "find" },
        alias: "PRS Approvals",
        requiredFilters: [],
        defaultSort: {
            field: "createdAt",
            order: "ASC",
        },
    },
};

const configs = {
    stg: {
        linkToNestrom: "https://alfuttaim.stg.fieldradar.io/",
        resources,
        // apiLink: "http://localhost:1337",
        apiLink: "https://alfuttaim.stg-prs.fieldradar.io/api",
    },
    prod: {
        linkToNestrom: "https://alfuttaim.nestrom.io/",
        resources,
        apiLink: "https://alfuttaim.prs.fieldradar.io/api",
        // apiLink: "http://ashar:1338",
    },
};

console.log("REACT_APP_NESTROM_ENV", process.env.REACT_APP_NESTROM_ENV);
module.exports =
    configs[process.env.REACT_APP_NESTROM_ENV || "stg"] || configs["stg"];